import {profile_pull_payment_options} from '@/api/service'
export default {
    namespace:'payment',
    state:{
        paymentData:undefined
    },
    reducers:{
        changePayment(state,{payload}){
            return{
                ...state,
                paymentData:payload
            }
        }
    },
    effects: {
        *getPaymentEffects({payload},{call,put,select}){
            const data = yield call(profile_pull_payment_options,{
                startCardId:'',
                limit:999
              });
            yield put({
                type:'changePayment',
                payload:data
            })
        }
    }
}