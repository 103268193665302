import { orderDetail} from '../../../api/service';
import { Map,List } from 'immutable'
export default {
  namespace: 'orderDetail',
  state: {
    detailData:'',
  },
  effects: {
      *getDetailDataEffect({ payload }, { call, put, select }) {
        try {
          let params = {
                orderUuid:payload.value
          }
          const data = yield call(orderDetail,params)
          console.log('data==详情',data)
          if(data?.status!='error')
          {
            yield put({
              type:'changeDetailData',
              payload:data.data
          })
          }
        } catch (error) {
          console.log('error:',error)
        }
      },
  },
  reducers: {
    changeDetailData(state, { payload }) {
      console.log(payload)
      
      // let data = payload.deliveryAddress.replace(/,/g,"<br/>")
      // payload.deliveryAddress = data
      return {
        ...state,
        detailData: payload,
      };
    },
  }
};
