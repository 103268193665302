import { Map } from 'immutable';
import { passportSendSMS,
        verifySMSCodeServiceQuickLogin,
         registerByPhone, 
         sendsms,
         registerForCoupon,
         registerForActivity,
         freeGiftDetail,
         recommendDetail,
         verifySMSCodeService } from '../../../api/service';
import Cookies from 'js-cookie';
import {history} from 'umi';
import { NAEventType, sendRecord } from '@/utils/aws_analytics.js';

const formatPhoneNumber = (prefix,number)=>{
   let formatNumber = '';
   if(number){
    formatNumber = number.replace(/[\-\(\)\s]/g,'');
   }
   return prefix+' '+formatNumber;
}

// 埋点 推送手机号码到matomo平台
function pushUid(uid) {
  var _mtm = window._mtm = window._mtm || [];
  const index = _mtm.findIndex(item => item.uid);

  if (index !== -1) {
    // 存在 uid 对象,先删除
    _mtm.splice(index, 1);
  }

  // 再push新对象
  _mtm.push({uid});
}

const loginForward = ()=>{
  let callback = history.location.query.callback;
  if(history.location.pathname === '/cart'){
    setTimeout(()=>{
      window.location.reload()
    },500)
    return
  }
  if(callback){
    callback = decodeURIComponent(callback);
  }else{
    callback = "/";
  }
  history.replace(callback);
}

const defaultState = Map({
  freeGiftProduct:Map({}),
  activedFlowType: 'phone', // phone,email;
  phoneSwiperIndex:0,
  emailSwiperIndex:0,
  phone: Map({
    prefixVisible: false,
    number: '',
    prefix: '+1',
    submitting: false,
    showError: false,
    errorMsg: '',
    countingDown:false,
    countDownTime:60,
  }),
  otp:Map({
    code:'',
    showError:false,
    errorMsg:'',
    submitting:false,
    showResendResult:false,
    resendResultStatus:true,
    resendResultMsg:'',
  }),
  name:Map({
    text:'',
    instagramUsername:'',
    showError:false,
    errorMsg:'',
    submitting:false,
  }),
  email:Map({
    existsAccount:0,
    text:'',
    showError:false,
    errorMsg:'',
    submitting:false,
  }),
  password:Map({
    rememberMe:1,
    text:'',
    showError:false,
    errorMsg:'',
    submitting:false,
  }),
  emailRegister:Map({
    showError:false,
    errorMsg:'',
    name:'',
    password:'',
    submitting:false,
  }),
  accountExists:1,
  loginType:2 ,//1 10coupon, 2 free gift
});

export default {
  namespace: 'loginQuick',
  state: defaultState,
  reducers: {
    _changeLoginType(state, { payload }){
      state = state.set('loginType',payload)
      return state
    },
    _changePhonePrefixVisible(state, { payload }) {
      state = state.setIn(['phone', 'prefixVisible'], payload.visible);
      return state;
    },
    _changePhoneNumber(state, { payload }) {
      state = state.setIn(['phone', 'number'], payload.value.number);
      state = state.setIn(['phone', 'prefix'], payload.value.prefix);
      return state;
    },
    _changePhoneFormSubmitting(state, { payload }) {
      state = state.setIn(['phone', 'submitting'], payload.value);
      return state;
    },
    _changePhoneError(state, { payload }) {
      state = state.setIn(['phone','showError'],payload.showError);
      state = state.setIn(['phone','errorMsg'],payload.errorMsg);
      return state;
    },
    _changeCountDown(state,{payload}){
      state = state.setIn(['phone','countingDown'],payload.countingDown);
      state = state.setIn(['phone','countDownTime'],payload.countDownTime);
      return state;
    },
    _changeCountDownTime(state,{payload}){
      state = state.setIn(['phone','countDownTime'],payload.value);
      return state;
    },
    _changeSwiperPhoneIndex(state,{payload}){
      state = state.set('phoneSwiperIndex',payload.index);
      return state;
    },
    _changeOTPCode(state,{payload}){
      state = state.setIn(['otp','code'],payload.value);
      return state;
    },
    _initialPhoneFlow(state,{payload}){
      state = state.set('phone',defaultState.get('phone'));
      state = state.set('otp',defaultState.get('otp'));
      state = state.set('name',defaultState.get('name'));
      state = state.set('activedFlowType','phone');
      state = state.set('phoneSwiperIndex',0);

      return state;
    },
    _changeOTPError(state,{payload}){
      state = state.setIn(['otp','showError'],payload.showError);
      state = state.setIn(['otp','errorMsg'],payload.errorMsg);
      return state;
    },
    _changeOTPSubmitting(state,{payload}){
      state = state.setIn(['otp','submitting'],payload.value);
      return state;
    },
    _changeResendSMSResult(state,{payload}){
      state = state.setIn(['otp','showResendResult'],payload.show);
      state = state.setIn(['otp','resendResultStatus'],payload.status);
      state = state.setIn(['otp','resendResultMsg'],payload.msg);
      return state;
    },
    _changeNameSubmitting(state,{payload}){
        state = state.setIn(['name','submitting'],payload.value);
        return state;
    },
    _changeNameShowError(state,{payload}){
        state = state.setIn(['name','showError'],payload.showError);
        state = state.setIn(['name','errorMsg'],payload.errorMsg);
        return state;
    },
    _cleanLoginRedux(state,{payload}){
      return defaultState;
    },
    _changeFlowType(state,{payload}){
      state = defaultState;
      state = state.set('activedFlowType',payload.value);
      return state;
    },
    _changeEmailSubmitting(state,{payload}){
      state = state.setIn(['email','submitting'],payload.value);
      return state;
    },
    _changeEmailShowError(state,{payload}){
      state = state.setIn(['email','showError'],payload.showError);
      state = state.setIn(['email','errorMsg'],payload.errorMsg);
      return state;
    },
    _changeEmail(state,{payload}){
      state = state.setIn(['email','text'],payload.value);
      return state;
    },
    _changeEmailExists(state,{payload}){
      state = state.setIn(['email','existsAccount'],payload.value);
      return state;
    },
    _changeSwiperEmailIndex(state,{payload}){
      state = state.set('emailSwiperIndex',payload.value);
      return state;
    },
    _changePassword(state,{payload}){
      state = state.setIn(['password','text'],payload.value);
      return state;
    },
    _changePasswordSubmitting(state,{payload}){
      state = state.setIn(['password','submitting'],payload.value);
      return state;
    },
    _changePasswordShowError(state,{payload}){
      state = state.setIn(['password','showError'],payload.showError);
      state = state.setIn(['password','errorMsg'],payload.errorMsg);
      return state;
    },
    _initialEmailFlow(state,{payload}){
      state = state.set('email',defaultState.get('email'));
      state = state.set('password',defaultState.get('password'));
      state = state.set('emailRegister',defaultState.get('emailRegister'));
      state = state.set('activedFlowType','email');
      state = state.set('emailSwiperIndex',0);

      return state;
    },
    _changeEmailRegisterForm(state,{payload}){
      state = state.setIn(['emailRegister','name'],payload.name);
      state = state.setIn(['emailRegister','password'],payload.password);
      return state;
    },
    _changeEmailRegisterSubmitting(state,{payload}){
      state = state.setIn(['emailRegister','submitting'],payload.value);
      return state;
    },
    _changeEmailRegisterShowError(state,{payload}){
      state = state.setIn(['emailRegister','showError'],payload.showError);
      state = state.setIn(['emailRegister','errorMsg'],payload.errorMsg);
      return state;
    },
    _changeEmailPasswordRememberMe(state,{payload}){
      state = state.setIn(['password','rememberMe'],payload.value);
      return state;
    },
    _changeAccountExists(state,{payload}){
      state = state.set('accountExists',payload);
      return state;
    },
    _changeFreeGiftDetail(state,{payload}){
      state = state.set('freeGiftProduct',payload)
      return state
    },
    _changeNameInPhoneFlow(state,{payload}){
      if(payload.type=='instagramUsername'){
         state = state.setIn(['name','instagramUsername'],payload.value);
      }else if(payload.type=='text'){
        state = state.setIn(['name','text'],payload.value);
      }
      return state;
  },
  },
  effects: {
    *changeFreeGiftDetail({payload},{call,put,select}){
      const loginType = payload.type
      const type = history.location.query.activityType || loginType
      const p = history.location.query.p
      const data = type == 3 ? yield call(recommendDetail) : yield call(freeGiftDetail,{id:payload.id,p:payload.p})
      if(data.status == "success"){
        yield put({
          type:'_changeFreeGiftDetail',
          payload:Map(data.data)
        })
        yield put({
          type:'_changeLoginType',
          payload:type
        })
        if(data.data.canReceive == 'N' ){
          history.push({pathname:'/loginresult',query:{
            type: 2,
            id:payload.id,
            p
          }})
        }
      }
      if(data.code == 'failed'){
        history.push({
          pathname:'/page-404'
        })
      }
    },
    *changeLoginType({ payload }, { call, put, select }){
      yield put({
        type:'_changeLoginType',
        payload
      })
    },
    *changePhonePrefixVisible({ payload }, { call, put, select }) {
      yield put({
        type: '_changePhonePrefixVisible',
        payload,
      });
    },
    *changePhoneNumber({ payload }, { call, put, select }) {
      yield put({
        type: '_changePhoneNumber',
        payload,
      });
    },
    *changePhoneFormSubmitting({ payload }, { call, put, select }) {
      yield put({
        type: '_changePhoneFormSubmitting',
        payload,
      });
    },
    *submitPhoneForm({ payload }, { call, put, select }) {
      yield put({
        type: 'changePhoneFormSubmitting',
        payload: { value: true },
      });
      yield put({
        type:'_changePhoneError',
        payload:{showError:false,errorMsg:''}
      })
      const {number,prefix} = yield select(({loginQuick})=>{
        return {
            number:loginQuick.getIn(['phone','number']),
            prefix:loginQuick.getIn(['phone','prefix']),
        }
      });
      const response = yield call(passportSendSMS, {
        phoneNumber: formatPhoneNumber(prefix,number),
      });
      yield put({
        type: 'changePhoneFormSubmitting',
        payload: { value: false },
      });
      if (response.status == 'error') {
        yield put({
          type:'_changePhoneError',
          payload:{showError:true,errorMsg:response.message}
        })
      } else {
        yield put({type:'_changeAccountExists',payload:response.data.accountExists})
        yield put({type:'_changeCountDown',payload:{countingDown:true,countDownTime:60}});
        yield put({type:'changeSwiperPhoneIndex',payload:{index:1}});
      }
    },

    *sendInviteSMS({payload},{call,put,select}){
      console.log('resendSMS_sendInviteSMS')
      yield put({
        type: 'changePhoneFormSubmitting',
        payload: { value: true },
      });
      yield put({
        type:'_changePhoneError',
        payload:{showError:false,errorMsg:''}
      })
      const {number,prefix} = yield select(({loginQuick})=>{
        return {
            number:loginQuick.getIn(['phone','number']),
            prefix:loginQuick.getIn(['phone','prefix']),
        }
      });
      const response = yield call(sendsms, {
        phoneNumber: formatPhoneNumber(prefix,number),
      });
      yield put({
        type: 'changePhoneFormSubmitting',
        payload: { value: false },
      });
      if (response.status == 'error') {
        yield put({
          type:'_changePhoneError',
          payload:{showError:true,errorMsg:response.message}
        })
      } else {
        yield put({type:'_changeAccountExists',payload:response.data.accountExists})
        yield put({type:'_changeCountDown',payload:{countingDown:true,countDownTime:60}});
        yield put({type:'changeSwiperPhoneIndex',payload:{index:1}});
      }
    },
    *changeCountDownTime({payload},{call,put,select}){
      yield put({
        type:'_changeCountDownTime',
        payload
      });
    },
    *changeCountDown({payload},{call,put,select}){
      yield put({type:'_changeCountDown',payload});
    },
    *changeSwiperPhoneIndex({payload},{put}){
      yield put({type:'_changeSwiperPhoneIndex',payload:payload})
    },
    *changeOTPCode({payload},{put}){
      yield put({type:'_changeOTPCode',payload});
    },
    *verifySMSCode({payload},{put,call,select}){
      yield put({type:'_changeOTPSubmitting',payload:{value:true}});
      const code = payload.code;
      const {number,prefix} = yield select(({loginQuick})=>{
        return {
          number:loginQuick.getIn(['phone','number']),
          prefix:loginQuick.getIn(['phone','prefix'])
        }
      });
      yield put({type:'_changeOTPError',payload:{showError:false,errorMsg:''}});
      const phoneNumber = formatPhoneNumber(prefix,number);
      const response = yield call(verifySMSCodeServiceQuickLogin,{code,phoneNumber,type:'phone',username:phoneNumber,password:code,rememberMe:1});
      yield put({type:'_changeOTPSubmitting',payload:{value:false}});
      if(response.status == 'error'){
        yield put({type:'_changeOTPError',payload:{showError:true,errorMsg:response['message']}});
        payload.reject(response)
      }else{
        payload.resolve(response)
        let successData = response['data'];
          if(!successData){
            successData = {};
          }
          yield put({type:'_cleanLoginRedux'});
          if(successData['haveProductAddToCart']==1){
            yield put({
              type:'headInfo/changeBounced',
              payload:{
                  BouncedFlag:true,
                  BouncedName:'Added to Cart!',
                  BouncedBtn:'View Cart',
                  btn_src:'/cart'
              }
          })
          }
          // loginForward();
      }

    },
    *initialPhoneFlow({payload},{call,put}){
      yield put({type:'_initialPhoneFlow'});
      yield put({type:'changeSwiperPhoneIndex',payload:{index:0}})
    },
    *resendSMS({payload},{call,put,select}){
      
      const {number,prefix} = yield select(({loginQuick})=>{
        return {
            number:loginQuick.getIn(['phone','number']),
            prefix:loginQuick.getIn(['phone','prefix'])
        }
      });
      const response = yield call(passportSendSMS, {
        phoneNumber: formatPhoneNumber(prefix,number),
      });
      if(response['status']=='error'){
        yield put({type:'_changeResendSMSResult',payload:{show:true,status:false,msg:response['message']}});
      }else{
        yield put({type:'_changeResendSMSResult',payload:{show:true,status:true,msg:''}});
      }
    },
    *closeResendResult({payload},{call,put}){
        yield put({type:'_changeResendSMSResult',payload:{show:false,status:true,msg:''}});
    },
    *phoneRegister({payload},{call,put,select}){
        yield put({type:'_changeNameSubmitting',payload:{value:true}});
        yield put({type:'_changeNameShowError',payload:{showError:false,errorMsg:''}})
        const {number,prefix,code,name} = yield select(({loginQuick})=>{
            return {
                number:loginQuick.getIn(['phone','number']),
                prefix:loginQuick.getIn(['phone','prefix']),
                code:loginQuick.getIn(['otp','code']),
                name:loginQuick.getIn(['name','text'])
            }
        });

        const response = yield call(registerByPhone,{phoneNumber:formatPhoneNumber(prefix,number),name,type:'phone',username:formatPhoneNumber(prefix,number),password:code,rememberMe:1});
        yield put({type:'_changeNameSubmitting',payload:{value:false}});
        if(response['status']=='error'){
          yield put({type:'_changeNameShowError',payload:{showError:true,errorMsg:response['message']}});
        }else{
          yield put({type:'_cleanLoginRedux'});
          let successData = response['data'];
          if(!successData){
            successData = {};
          }
          if(successData['haveProductAddToCart']==1){
            yield put({
              type:'headInfo/changeBounced',
              payload:{
                  BouncedFlag:true,
                  BouncedName:'Added to Cart!',
                  BouncedBtn:'View Cart',
                  btn_src:'/cart'
              }
          })
          }
          loginForward();
        }
        
    },
    *phoneRegisterCoupon({payload},{put,call,select}){
      yield put({type:'_changeOTPSubmitting',payload:{value:true}});
      const code = payload.code;
      const {number,prefix} = yield select(({loginQuick})=>{
        return {
          number:loginQuick.getIn(['phone','number']),
          prefix:loginQuick.getIn(['phone','prefix'])
        }
      });
      yield put({type:'_changeOTPError',payload:{showError:false,errorMsg:''}});
      const phoneNumber = formatPhoneNumber(prefix,number);
      const response = yield call(registerForCoupon,{code,phoneNumber,type:'phone',username:phoneNumber,password:code,rememberMe:1});
      yield put({type:'_changeOTPSubmitting',payload:{value:false}});
      if(response.status == 'error'){
        yield put({type:'_changeOTPError',payload:{showError:true,errorMsg:response['message']}});
        payload.reject(response)
      }else{
        payload.resolve(response)
        let successData = response['data'];
          if(!successData){
            successData = {};
          }
          // yield put({type:'_cleanLoginRedux'});
          if(successData['haveProductAddToCart']==1){
            yield put({
              type:'headInfo/changeBounced',
              payload:{
                  BouncedFlag:true,
                  BouncedName:'Added to Cart!',
                  BouncedBtn:'View Cart',
                  btn_src:'/cart'
              }
          })
          }
          // loginForward();
      }

    },
    
    *phoneRegisterForActivity({payload},{put,call,select}){
      yield put({type:'_changeOTPSubmitting',payload:{value:true}});
      const activityType = payload.activityType;
      const skuUuId = payload.skuUuId
      const id = payload.id || ''
      const referralCode = payload.referralCode
      const {number,prefix,name,instagramUsername,code} = yield select(({loginQuick})=>{
        return {
          number:loginQuick.getIn(['phone','number']),
          prefix:loginQuick.getIn(['phone','prefix']),
          name:loginQuick.getIn(['name','text']),
          instagramUsername:loginQuick.getIn(['name','instagramUsername']),
          code:loginQuick.getIn(['otp','code']),

        }
      });
      yield put({type:'_changeOTPError',payload:{showError:false,errorMsg:''}});
      const phoneNumber = formatPhoneNumber(prefix,number);
      const response = yield call(registerForActivity,{code,activityType,id,skuUuId,referralCode,phoneNumber,type:'phone',username:phoneNumber,password:phoneNumber,rememberMe:1,name,instagramUsername});
      yield put({type:'_changeOTPSubmitting',payload:{value:false}});
      if(response.status == 'error'){
        yield put({type:'_changeOTPError',payload:{showError:true,errorMsg:response['message']}});
        payload.reject(response)
      }else{
        payload.resolve(response)
        let successData = response['data'];
          if(!successData){
            successData = {};
          }
          // yield put({type:'_cleanLoginRedux'});
          if(successData['haveProductAddToCart']==1){
            yield put({
              type:'headInfo/changeBounced',
              payload:{
                  BouncedFlag:true,
                  BouncedName:'Added to Cart!',
                  BouncedBtn:'View Cart',
                  btn_src:'/cart'
              }
          })
          }
          // loginForward();
      }

    },
    *changeNameInPhoneFlow({payload},{call,put,select}){
      yield put({type:'_changeNameInPhoneFlow',payload});
    },
    *verifySMSCode({payload},{put,call,select}){
      yield put({type:'_changeOTPSubmitting',payload:{value:true}});
      const {number,prefix,code,loginType} = yield select(({loginQuick})=>{
        return {
          number:loginQuick.getIn(['phone','number']),
          prefix:loginQuick.getIn(['phone','prefix']),
          code:loginQuick.getIn(['otp','code']),
          loginType:loginQuick.get('loginType')
        }
      });
      yield put({type:'_changeOTPError',payload:{showError:false,errorMsg:''}});
      const phoneNumber = formatPhoneNumber(prefix,number);
      const response = yield call(verifySMSCodeService,{code,phoneNumber,type:'phone',username:phoneNumber,password:code,rememberMe:1});
      yield put({type:'_changeOTPSubmitting',payload:{value:false}});
      if(response.status == 'error'){
        yield put({type:'_changeOTPError',payload:{showError:true,errorMsg:response['message']}});
      }else{
        localStorage.setItem('uid',phoneNumber);
        pushUid(phoneNumber);
        if(response['data'] &&
           response['data']['code']=='toRegister'){ // 进行注册
           yield put({type:'changeSwiperPhoneIndex',payload:{index:2}});
        }else{ // 登录成功
          let successData = response['data'];
          if(!successData){
            successData = {};
          }
          if(successData['haveProductAddToCart']==1){
            yield put({
              type:'headInfo/changeBounced',
              payload:{
                  BouncedFlag:true,
                  BouncedName:'Added to Cart!',
                  BouncedBtn:'View Cart',
                  btn_src:'/cart'
              }
            })
          }
          
          put({
            type: 'headInfo/getLoginInfoEffects',
          });
          put({
            type: 'mycartData/getCartInfoEffect'
          });
          if(history.location.pathname !== '/cart'){
            let naContent = NAEventType.event.content;
            naContent.category = 'click';
            naContent.label = `freebie page Login Success`; 
            sendRecord({
              type: NAEventType.event.type,
              content: naContent,
            });
            if(loginType == 2){
              let id = history.location.query.id
              const p = sessionStorage.getItem('freebie_skuUuId')
              history.push({pathname:'/claimfreebie',query:{
                id,
                p
              }})
            }else{
              let id = history.location.query.id
              const p = sessionStorage.getItem('freebie_skuUuId')
              console.log('coupon 登录流程---',loginType)
              history.push({pathname:'/loginresult',query:{
                type:loginType,
                id:id,
                p
              }})
            }
          }else{
            let naContent = NAEventType.event.content;
            naContent.category = 'click';
            naContent.label = `cart Login Success`; 
            sendRecord({
              type: NAEventType.event.type,
              content: naContent,
            });
            payload.resolve({status:'success'})
          }
        }
      }

    },
  },
  
};
