// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/Applications/XAMPP/xamppfiles/htdocs/www/www/ModCartMobileFrontend/FrontendNewModCart/node_modules/_@umijs_runtime@3.5.34@@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/pages/loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/forward",
    "key": "forward",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__forward__index' */'/Applications/XAMPP/xamppfiles/htdocs/www/www/ModCartMobileFrontend/FrontendNewModCart/src/components/forward/index'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/",
    "key": "/base",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__base__index' */'@/pages/base/index'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/favorites",
        "title": "My Favorites  - ModCart Living",
        "key": "/favorites",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__favoritesList__index' */'@/pages/favoritesList/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/start",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/start",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/get10",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/get10",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/home",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/shop",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/shop",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/freedelivery",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/freedelivery",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/homepage",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/homepage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/essentials",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/essentials",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/shopessentials",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/shopessentials",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/nextdaydelivery",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/nextdaydelivery",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/atlanta",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/atlanta",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/cart",
        "key": "/cart",
        "title": "Cart - ModCart Living",
        "badgeKey": "myCart",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__myCart__index' */'@/pages/myCart/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/deal",
        "key": "/deal",
        "title": "deal",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__themelist__index' */'@/pages/themelist/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/getcoupon",
        "key": "/getcoupon",
        "title": "New User",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invite__couponOnly' */'@/pages/invite/couponOnly'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/p",
        "key": "/p",
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__details__index' */'@/pages/details/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/preview",
        "key": "/preview",
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__details__preview' */'@/pages/details/preview'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/newuser",
        "key": "/newuser",
        "title": "New User",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invite__couponOnly' */'@/pages/invite/couponOnly'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/loginresult",
        "key": "/loginresult",
        "title": "New User",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__loginResult__index' */'@/pages/loginResult/index'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/claimfreebie",
        "key": "/claimfreebie",
        "title": "Free Gift",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__freegiftAddress__index' */'@/pages/freegiftAddress/index'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/getfreebie",
        "key": "/getfreebie",
        "title": "Free Gift",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invite__freebieOnly' */'@/pages/invite/freebieOnly'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/login",
        "key": "/login",
        "title": "Sign in / Sign up",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login__index' */'@/pages/login/index'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/resetpassword",
        "key": "/resetpassword",
        "title": "Reset Password",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__resetPassword__index' */'@/pages/resetPassword/index'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/newpassword**",
        "key": "/newpassword",
        "title": "Reset Password",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__resetPassword__NewPassword' */'@/pages/resetPassword/NewPassword'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/needhelp",
        "key": "/needhelp",
        "title": "Reset Password",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__resetPassword__NeedHelp' */'@/pages/resetPassword/NeedHelp'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/c/:name",
        "key": "/c",
        "title": "Shop By Category",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__categoryGoodListScrollX__index' */'@/pages/categoryGoodListScrollX/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/addresses",
        "key": "/addresses",
        "title": "My addresses - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__addressBook__index' */'@/pages/addressBook/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/addressedit",
        "key": "/addressedit",
        "title": "My addresses - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__addressEdit__index' */'@/pages/addressEdit/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/checkorder",
        "key": "/checkorder",
        "title": "Checkout - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__checkOrder__index' */'@/pages/checkOrder/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/checkoutresult",
        "key": "/checkoutresult",
        "title": "Thanks Order - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__checkOutResult__index' */'@/pages/checkOutResult/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/payments",
        "key": "/payments",
        "title": "Payment Options - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__authorizePaymentList__index' */'@/pages/authorizePaymentList/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/orderthanks/:id",
        "key": "/orderthanks",
        "title": "checkOutResult",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orderThanks__index' */'@/pages/orderThanks/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/paymentadd",
        "key": "/paymentadd",
        "title": "Payment Options - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__authorizePaymentAdd__index' */'@/pages/authorizePaymentAdd/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/orders",
        "key": "/orders",
        "title": "Placed Orders - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orderHistory__index' */'@/pages/orderHistory/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/orderdetail",
        "key": "/orderdetail",
        "title": "Order Details - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orderDetail__index' */'@/pages/orderDetail/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/account",
        "key": "/account",
        "title": "My Profile - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mine__index' */'@/pages/mine/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/search",
        "key": "/search",
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__search__index' */'@/pages/search/index'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/s",
        "key": "/s",
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__searchResult__index' */'@/pages/searchResult/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/editprofile",
        "key": "/editprofile",
        "title": "My Profile - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__editUserProfile__index' */'@/pages/editUserProfile/index'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/returnorreplace",
        "key": "/returnorreplace",
        "title": "Return | Replace - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__returnOrReplace__index' */'@/pages/returnOrReplace/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/return",
        "key": "/return",
        "title": "Return - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__return__index' */'@/pages/return/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/replace",
        "key": "/replace",
        "title": "Replace - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__return__index' */'@/pages/return/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/buyagain",
        "key": "/buyagain",
        "title": "Buy Again - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__buyAgain__index' */'@/pages/buyAgain/index'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/coupons",
        "key": "/coupons",
        "title": "My Coupons - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__coupons__index' */'@/pages/coupons/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/refer",
        "key": "/refer",
        "title": "Refer a friend - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__refer__index' */'@/pages/refer/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/invite",
        "key": "/invite",
        "title": "Sign in / Sign up - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__referSignUp__index' */'@/pages/referSignUp/index'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/instagram",
        "key": "/instagram",
        "title": "Instagram - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__instagram__index' */'@/pages/instagram/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/helpus",
        "key": "/helpus",
        "title": "Help Us - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__helpus__index' */'@/pages/helpus/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/faq",
        "key": "/faq",
        "title": "Frequently Asked Questions - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__helpus__faq__index' */'@/pages/helpus/faq/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/contactus",
        "key": "/contactus",
        "title": "About Us/Contact Us - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__helpus__aboutAndContactUs__index' */'@/pages/helpus/aboutAndContactUs/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/policies",
        "key": "/policies",
        "title": "Our Policies/Terms of Use - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__helpus__policiesAndTerms__index' */'@/pages/helpus/policiesAndTerms/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/threejsdemo",
        "key": "/threejsdemo",
        "title": "threejsdemo - ModCart ATL",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__threejs__threeDemo' */'@/pages/threejs/threeDemo.tsx'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/page-404",
        "key": "/page-404",
        "title": "404 - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404__index' */'@/pages/404/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/*",
        "redirect": "/page-404",
        "exact": true
      },
      {
        "path": "/**/*",
        "redirect": "/page-404",
        "exact": true
      }
    ]
  },
  {
    "path": "/forward",
    "key": "forward",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__forward__index' */'/Applications/XAMPP/xamppfiles/htdocs/www/www/ModCartMobileFrontend/FrontendNewModCart/src/components/forward/index'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/",
    "key": "/base",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__base__index' */'@/pages/base/index'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/favorites",
        "title": "My Favorites  - ModCart Living",
        "key": "/favorites",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__favoritesList__index' */'@/pages/favoritesList/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/start",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/start",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/get10",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/get10",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/home",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/shop",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/shop",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/freedelivery",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/freedelivery",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/homepage",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/homepage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/essentials",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/essentials",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/shopessentials",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/shopessentials",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/nextdaydelivery",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/nextdaydelivery",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/atlanta",
        "title": "ModCart Living - Curated items to fit your vibe, your space, and your look.",
        "key": "/atlanta",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/cart",
        "key": "/cart",
        "title": "Cart - ModCart Living",
        "badgeKey": "myCart",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__myCart__index' */'@/pages/myCart/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/deal",
        "key": "/deal",
        "title": "deal",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__themelist__index' */'@/pages/themelist/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/getcoupon",
        "key": "/getcoupon",
        "title": "New User",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invite__couponOnly' */'@/pages/invite/couponOnly'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/p",
        "key": "/p",
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__details__index' */'@/pages/details/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/preview",
        "key": "/preview",
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__details__preview' */'@/pages/details/preview'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/newuser",
        "key": "/newuser",
        "title": "New User",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invite__couponOnly' */'@/pages/invite/couponOnly'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/loginresult",
        "key": "/loginresult",
        "title": "New User",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__loginResult__index' */'@/pages/loginResult/index'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/claimfreebie",
        "key": "/claimfreebie",
        "title": "Free Gift",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__freegiftAddress__index' */'@/pages/freegiftAddress/index'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/getfreebie",
        "key": "/getfreebie",
        "title": "Free Gift",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invite__freebieOnly' */'@/pages/invite/freebieOnly'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/login",
        "key": "/login",
        "title": "Sign in / Sign up",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login__index' */'@/pages/login/index'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/resetpassword",
        "key": "/resetpassword",
        "title": "Reset Password",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__resetPassword__index' */'@/pages/resetPassword/index'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/newpassword**",
        "key": "/newpassword",
        "title": "Reset Password",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__resetPassword__NewPassword' */'@/pages/resetPassword/NewPassword'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/needhelp",
        "key": "/needhelp",
        "title": "Reset Password",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__resetPassword__NeedHelp' */'@/pages/resetPassword/NeedHelp'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/c/:name",
        "key": "/c",
        "title": "Shop By Category",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__categoryGoodListScrollX__index' */'@/pages/categoryGoodListScrollX/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/addresses",
        "key": "/addresses",
        "title": "My addresses - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__addressBook__index' */'@/pages/addressBook/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/addressedit",
        "key": "/addressedit",
        "title": "My addresses - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__addressEdit__index' */'@/pages/addressEdit/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/checkorder",
        "key": "/checkorder",
        "title": "Checkout - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__checkOrder__index' */'@/pages/checkOrder/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/checkoutresult",
        "key": "/checkoutresult",
        "title": "Thanks Order - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__checkOutResult__index' */'@/pages/checkOutResult/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/payments",
        "key": "/payments",
        "title": "Payment Options - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__authorizePaymentList__index' */'@/pages/authorizePaymentList/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/orderthanks/:id",
        "key": "/orderthanks",
        "title": "checkOutResult",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orderThanks__index' */'@/pages/orderThanks/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/paymentadd",
        "key": "/paymentadd",
        "title": "Payment Options - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__authorizePaymentAdd__index' */'@/pages/authorizePaymentAdd/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/orders",
        "key": "/orders",
        "title": "Placed Orders - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orderHistory__index' */'@/pages/orderHistory/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/orderdetail",
        "key": "/orderdetail",
        "title": "Order Details - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orderDetail__index' */'@/pages/orderDetail/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/account",
        "key": "/account",
        "title": "My Profile - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mine__index' */'@/pages/mine/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/search",
        "key": "/search",
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__search__index' */'@/pages/search/index'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/s",
        "key": "/s",
        "title": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__searchResult__index' */'@/pages/searchResult/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/editprofile",
        "key": "/editprofile",
        "title": "My Profile - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__editUserProfile__index' */'@/pages/editUserProfile/index'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/returnorreplace",
        "key": "/returnorreplace",
        "title": "Return | Replace - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__returnOrReplace__index' */'@/pages/returnOrReplace/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/return",
        "key": "/return",
        "title": "Return - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__return__index' */'@/pages/return/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/replace",
        "key": "/replace",
        "title": "Replace - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__return__index' */'@/pages/return/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/buyagain",
        "key": "/buyagain",
        "title": "Buy Again - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__buyAgain__index' */'@/pages/buyAgain/index'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/coupons",
        "key": "/coupons",
        "title": "My Coupons - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__coupons__index' */'@/pages/coupons/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/refer",
        "key": "/refer",
        "title": "Refer a friend - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__refer__index' */'@/pages/refer/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/invite",
        "key": "/invite",
        "title": "Sign in / Sign up - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__referSignUp__index' */'@/pages/referSignUp/index'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/instagram",
        "key": "/instagram",
        "title": "Instagram - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__instagram__index' */'@/pages/instagram/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/helpus",
        "key": "/helpus",
        "title": "Help Us - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__helpus__index' */'@/pages/helpus/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/faq",
        "key": "/faq",
        "title": "Frequently Asked Questions - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__helpus__faq__index' */'@/pages/helpus/faq/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/contactus",
        "key": "/contactus",
        "title": "About Us/Contact Us - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__helpus__aboutAndContactUs__index' */'@/pages/helpus/aboutAndContactUs/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/policies",
        "key": "/policies",
        "title": "Our Policies/Terms of Use - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__helpus__policiesAndTerms__index' */'@/pages/helpus/policiesAndTerms/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/threejsdemo",
        "key": "/threejsdemo",
        "title": "threejsdemo - ModCart ATL",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__threejs__threeDemo' */'@/pages/threejs/threeDemo.tsx'), loading: LoadingComponent}),
        "header": false,
        "exact": true
      },
      {
        "path": "/page-404",
        "key": "/page-404",
        "title": "404 - ModCart Living",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404__index' */'@/pages/404/index'), loading: LoadingComponent}),
        "header": true,
        "exact": true
      },
      {
        "path": "/*",
        "redirect": "/page-404",
        "exact": true
      },
      {
        "path": "/**/*",
        "redirect": "/page-404",
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
