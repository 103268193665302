import {List,Map,fromJS} from 'immutable';
import {suAddress, getEditAddress,getUSstate} from '@/api/service';
import {history} from 'umi';
import URLParse from 'url-parse';
import queryString from 'query-string';

import { delay } from 'dva/saga';
import {parsePhoneNumber} from 'react-phone-number-input';

function formatPhoneNumber(input){
  const phoneNumber = parsePhoneNumber(input)
  if(phoneNumber){
    return '+'+phoneNumber.countryCallingCode+' '+phoneNumber.nationalNumber;
  }else{
    return '';
  }
}

/**
 * gei url 中塞一个参数。
 * @param callBack 
 * @param key 
 * @param value 
 * @returns 
 */
 function callBackSetQuery(callBack,key,value){
  const URL = URLParse(callBack);
  const parsed = queryString.parse(URL.query);
  parsed[key] = value;

  return URL.pathname +'?'+queryString.stringify(parsed);
}

function *finishLoading(put){
  yield put.resolve({type:'_changeFormLoading',payload:{value:false}});
}

const defaultState = Map({
  address:Map({
    id:'',
    country:'United States',
    address1:'',
    address2:'',
    usState:'',
    cnState:'Beijing',
    dfState:'',
    city:'',
    zipCode:'',
    contactName:'',
    contactPhone:'',
    securityCode:'',
    callBox:'',
    isDefault:0
  }),
  formLoading:false,
  submitting:false,
  showError:false,
  errorMsg:'',
  pageType:'', // 'shipping_address';'billing_address';'';
  callBack:'',
  usStateMap:[],
  stateUSCode:0,
  zipCodeError:false
});

export default {
  namespace:'orderAddress',
  state: defaultState,
  reducers:{
    _resetAddress(state,{payload}){
      state = state.set('address',defaultState.get('address'));
      return state;
    },
    _formChange(state,{payload}){
      const id = state.getIn(['address','id']);
      state = state.set('address',Map({id,...payload}));
      return state;
    },
    _submitting(state,{payload}){
      state = state.set('submitting',payload.value);
      return state;
    },
    _showError(state,{payload}){
      state = state.set('showError',payload.showError);
      state = state.set('errorMsg',payload.errorMsg);
      return state;
    },
    _initialState(state,{payload}){
      state = defaultState;
      state = state.set('pageType',payload.type);
      state = state.set('callBack',payload.callBack);
      return state;
    },
    _setAddress(state,{payload}){
      state = state.set('address',payload.value);
      return state;
    },
    _changeFormLoading(state,{payload}){
      state = state.set('formLoading',payload.value);
      return state;
    },
    _changeUSstateMap(state,{payload}){
      state = state.set('usStateMap',payload.value)
      return state
    },
    _changeStateUSCode(state,{payload}){
      console.log('stateUSCode',payload)
      state = state.set('stateUSCode',payload.value)
      return state
    },
    _changeZipcodeError(state,{payload}){
      state = state.set('zipCodeError',payload.value)
      return state
    }
  },
  effects:{
    *queryUSstate({payload},{select,call,put}){
      const res = yield call(getUSstate)
      if(res.status == 'success'){
        yield put({
          type:'_changeUSstateMap',
          payload:{value:res.data}
        })
      }else{
        console.log('getUSstate error')
      }
    },
    *formChange({payload},{select,call,put}){
      const {changedValue,allValues} = payload;
      let newPayload = {};
      newPayload = {
        ...allValues
      }
      yield put({type:'_formChange',payload:newPayload});
    },
    
    *submitForm({payload},{select,call,put}){
      yield put({type:'_submitting',payload:{value:true}});
      yield put({type:'_showError',payload:{showError:false,errorMsg:''}});

      const {address,
             callBack,
             pageType} = yield select(({addressForm})=>{
        return {address:addressForm.get('address'),
                callBack:addressForm.get('callBack'),
                pageType:addressForm.get('pageType')};
      });   
      let state = '';
      const country = address.get('country','');
      if(country=='United States'){
        state = address.get('usState','');
      }else if(country=='China'){
        state = address.get('cnState','');
      }else{
        state = address.get('dfState','');
      }
      let params = {
        contactName:address.get('contactName',''),
        contactPhone:formatPhoneNumber(address.get('contactPhone','')),
        address1:address.get('address1',''),
        address2:address.get('address2',''),
        state:state,
        city:address.get('city',''),
        zipCode:address.get('zipCode',''),
        securityCode:address.get('securityCode',''),
        callBox:address.get('callBox',''),
        isDefault:address.get('isDefault',0),
        country:address.get('country',''),
      }

      const result = yield call(suAddress,{id:address.get('id'),address:params});
      yield put({type:'_submitting',payload:{value:false}});
      if(result.status=='error'){
        yield put({type:'_showError',payload:{showError:true,errorMsg:result.message}});
      }else{
        let locationId = 0;
        if(result.data){
          locationId = result.data.locationId;
        }
        if(callBack && locationId){
          let queryKey = '';
          if(pageType=='billing_address'){
            queryKey = 'billing_address_id';
          }else{
            queryKey = 'address_id';
          }
          const query = history.location.query;
          history.push({pathname:callBack,query:{
            ...query,
            [queryKey]:locationId
          }});
        }else{
          history.go(-1);
        }
      }
    },
    *setLocation({payload},{put,select,call}){
      yield put({type:'_changeFormLoading',payload:{value:true}});
      const {addressId} = payload;
      const result = yield call(getEditAddress,addressId);
      if(result.status == 'success'){
        const address = result.data;
        let newAddress = defaultState.get('address');

        newAddress = newAddress.set('id',addressId);
        newAddress = newAddress.set('country',address['country']);
        newAddress = newAddress.set('address1',address['address1']);
        newAddress = newAddress.set('address2',address['address2']);
        newAddress = newAddress.set('city',address['city']);
        newAddress = newAddress.set('zipCode',address['zipCode']);
        newAddress = newAddress.set('contactName',address['contactName']);
        newAddress = newAddress.set('contactPhone',address['contactPhone']);
        newAddress = newAddress.set('securityCode',address['securityCode']);
        newAddress = newAddress.set('callBox',address['callBox']);
        newAddress = newAddress.set('isDefault',address['isDefault']);
        if(address['country'] == 'United States'){
          newAddress = newAddress.set('usState',address['state']);
        }else if(address['country']=='China'){
          newAddress = newAddress.set('cnState',address['state']);
        }else{
          newAddress = newAddress.set('dfState',address['state']);
        }
        yield put({type:'_setAddress',payload:{value:newAddress}})
        yield put({type:'_changeFormLoading',payload:{value:false}});
      }
    },
    *initialState({payload},{call,put,select}){
      yield put({type:'_initialState',payload});
    },
    *cleanForm({payload},{put,call}){
      yield put({type:'_changeFormLoading',payload:{value:true}});
      yield call(delay, 0);
      yield put({type:'_changeFormLoading',payload:{value:false}});
    }
  }
}