import moment from 'moment';
import React from 'react';
import SparkMD5 from "spark-md5";
import {isMobile} from 'react-device-detect';

// import nzh from 'nzh/cn';
import { parse, stringify } from 'qs';

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
    ];
  }

  const year = now.getFullYear();
  return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
}

export function isInWeek (beginTime) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;
  const nowTime = now.getTime()
  return (nowTime - Number(beginTime) < 7 * oneDay)
}

export function getPlainNode(nodeList, parentPath = '') {
  const arr = [];
  nodeList.forEach(node => {
    const item = node;
    item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/');
    item.exact = true;
    if (item.children && !item.component) {
      arr.push(...getPlainNode(item.children, item.path));
    } else {
      if (item.children && item.component) {
        item.exact = false;
      }
      arr.push(item);
    }
  });
  return arr;
}

// export function digitUppercase(n) {
//   return nzh.toMoney(n);
// }

function getRelation(str1, str2) {
  if (str1 === str2) {
    console.warn('Two path are equal!'); // eslint-disable-line
  }
  const arr1 = str1.split('/');
  const arr2 = str2.split('/');
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  }
  if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

function getRenderArr(routes) {
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    // 去重
    renderArr = renderArr.filter(item => getRelation(item, routes[i]) !== 1);
    // 是否包含
    const isAdd = renderArr.every(item => getRelation(item, routes[i]) === 3);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  return renderArr;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData) {
  let routes = Object.keys(routerData).filter(
    routePath => routePath.indexOf(path) === 0 && routePath !== path
  );
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map(item => item.replace(path, ''));
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  const renderRoutes = renderArr.map(item => {
    const exact = !routes.some(route => route !== item && getRelation(route, item) === 1);
    return {
      exact,
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`,
    };
  });
  return renderRoutes;
}

export function getPageQuery() {
  return parse(window.location.href.split('?')[1]);
}

export function getQueryPath(path = '', query = {}) {
  const search = stringify(query);
  if (search.length) {
    return `${path}?${search}`;
  }
  return path;
}

/* eslint no-useless-escape:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export function isUrl(path) {
  return reg.test(path);
}

export function formatWan(val) {
  const v = val * 1;
  if (!v || Number.isNaN(v)) return '';

  let result = val;
  if (val > 10000) {
    result = Math.floor(val / 10000);
    result = (
      <span>
        {result}
        <span
          style={{
            position: 'relative',
            top: -2,
            fontSize: 14,
            fontStyle: 'normal',
            marginLeft: 2,
          }}
        >
          万
        </span>
      </span>
    );
  }
  return result;
}

/**
 *  获取 UUID
 *
 * @param len 长度
 * @param radix 基数
 * @returns {string}
 */
export function generateUUID(len, radix) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const uuid = [];
  let i;
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    let r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return uuid.join('');
}

export const getFileMd5 = (file, chunkSize = 10 * 1024 * 1024) => {
  return new Promise((resolve, reject) => {
    var blobSlice =
      File.prototype.slice ||
      File.prototype.mozSlice ||
      File.prototype.webkitSlice,
      chunks = Math.ceil(file.size / chunkSize),
      currentChunk = 0,
      spark = new SparkMD5.ArrayBuffer(),
      fileReader = new FileReader();

    fileReader.onload = function (e) {
      spark.append(e.target.result); // Append array buffer
      currentChunk++;
      if (currentChunk < chunks) {
        loadNext();
      } else {
        let result = spark.end();
        resolve(result);
      }
    };

    fileReader.onerror = function (e) {
      reject(e);
    };

    function loadNext() {
      var start = currentChunk * chunkSize,
        end = start + chunkSize >= file.size ? file.size : start + chunkSize;
      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }

    loadNext();
  });
};

export function IArrayGet(map, key, default_value) {
  try {
    return map.has(key) ? map.get(key) : default_value;
  } catch (g) {
    return default_value;
  }
}

/*检测设备,ISPC true  pc;false wx*/
export function IsPC() {
  // var userAgentInfo = navigator.userAgent;
  // var Agents = ["Android", "iPhone",
  //     "SymbianOS", "Windows Phone",
  //     "iPad", "iPod"
  // ];
  // var flag = true;
  // for (var v = 0; v < Agents.length; v++) {
  //     if (userAgentInfo.indexOf(Agents[v]) > 0) {
  //         flag = false;
  //         break;
  //     }
  // }
  // return flag;
  const width = window.outerWidth;
  if(!isMobile){
    return true;
  }else if(width > 700){  // pad
    return true;
  }else{
    return false;
  }
  
}

//获取url参数
export function getQueryString(name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let r = window.location.search.substr(1).match(reg);
  if (r != null) {
      return decodeURIComponent(r[2]);
  };
  return null;
}


export function GetCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length == 2) {
    return parts
      .pop()
      .split(";")
      .shift();
  }
  return null;
}

export function SetCookie(name, value, path = '/',vEnd) {
  let sExpires = '';
  if(vEnd){
    sExpires = "; expires=" + vEnd;
  }
  document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + sExpires +";path=" + path;
}


export function Uuid(len, radix) {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  var uuid = [], i;
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
  } else {
    // rfc4122, version 4 form
    var r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random() * 16;
        uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return uuid.join('');
}



export function ParseURIParam(index, pathname = '') {
  if (pathname == '') {
    pathname = window.location.pathname;
  }
  var results = pathname.split("/").filter(function (n) {
    return n != undefined && n != "";
  });
  return results[index];
}

export function ParseURIParamStr(param) {
  var results = new RegExp('[\?&]' + param + '=([^&#]*)').exec(window.location.href);
  if (!results) {
    return '';
  }
  return results[1];
}


export const countTime = (nowtime,endtime,hRef,mRef,sRef) => {
  var leftTime = endtime - nowtime; //时间差                              
  var d, h, m, s, ms;
  if(leftTime >= 0) {
      // d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
      h = Math.floor(leftTime / 1000 / 60 / 60);
      m = Math.floor(leftTime / 1000 / 60 % 60);
      s = Math.floor(leftTime / 1000 % 60);
      ms = Math.floor(leftTime % 1000);
      if(ms < 100) {
          ms = "0" + ms;
      }
      if(s < 10) {
          s = "0" + s;
      }
      if(m < 10) {
          m = "0" + m;
      }
      if(h < 10) {
          h = "0" + h;
      }
  } else {
      console.log('已截止')
      return;
  }
  //将倒计时赋值到div中
  // document.getElementById("_d").innerHTML = d + "天";
  if(!hRef.current) return
  hRef.current.innerHTML = h + ":";
  mRef.current.innerHTML = m + ":";
  sRef.current.innerHTML = s 
  // document.getElementById("_ms").innerHTML = ms + "毫秒";
  //递归每秒调用countTime方法，显示动态时间效果
  const currentTime = nowtime + 1000
  setTimeout(()=>countTime(currentTime,endtime,hRef,mRef,sRef), 1000);
}

export const formatDate = (date) => {
  const dateArr = date.split(' ')[0].split('-')
  const formatStr = dateArr[1] + '/' + dateArr[2] + '/' + dateArr[0]
  return formatStr
} 

export const getformatDate = (time,string=false) => {
  if(!time) return
  const dayArr = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
]
  const monthArr = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]
  const num = {
    1:'1st',
    2:'2nd',
    3:'3rd'
  }
  const formatDate = new Date(time)
  if(string){
    const day = dayArr[formatDate.getDay()]
    const month = monthArr[formatDate.getMonth()]
    const date = formatDate.getDate()
    return (day + ',' + month + ' ' + (date > 3 ? date+'th' : num[date]))
  }else{
    const date = formatDate.getDate()
    const month = formatDate.getMonth() + 1
    const year = formatDate.getFullYear()
    return month + '/' + date + '/' + year
  }
}