import { getUserInfo } from '@/api/service';
export default {
    namespace: 'mine',
    state: {
        userInfo: {},
    },
    effects: {
        *getUserInfoEffects({ payload }, { call, put, select }) {
            const data = yield call(getUserInfo, payload);
            if(data.status == 'success'){
                yield put({
                    type: 'changeUserInfo',
                    payload: data.data,
                });
            }
        }
    },
    reducers: {
        changeUserInfo(state, { payload }) {
            return{
                ...state,
                userInfo: payload,
            }
            
        }
    }
}