import {querylivingHomeData} from '@/api/service'
export default {
    namespace:'livingdata',
    state:{
        livingPageData:{}
    },
    effects:{
        *getlivingpagedata({payload},{call,put,select}){
            const data = yield call(querylivingHomeData,null);
            console.log('changelivingPageData==', data);
            //put 发出一个 Action，调用reducers里的方法，类似于 dispatch
            yield put({
                // 调用当前 model 的 action 不需要添加 namespace
                type: 'changelivingPageData',
                payload: data,
            });
        }
    },
    reducers:{
        changelivingPageData(state, { payload }) {
            return {
              ...state,
              livingPageData: payload,
            };
          },
    }
}