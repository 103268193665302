import { resetPwdReq } from '../../../api/service';
import { Map,List } from 'immutable';
export default {
  //命名空间
  namespace: 'resetPwd',
  state: {
    //初始数据
  },
  effects: {
    //管理异步操作
    *resetPwdReq({ payload }, { call, put, select }) {
      const value = {...payload};
      console.log(value);
      const params = { email:value.email};
      //call 调用异步请求
      const data = yield call(resetPwdReq,params);
      console.log('effects=232=', data);
      //put 发出一个 Action，调用reducers里的方法，类似于 dispatch
      // yield put({
      //   // 调用当前 model 的 action 不需要添加 namespace
      //   type: 'changehomePageData',
      //   payload: data,
      // });
    },
    
  },
  reducers: {
    
  },
};
