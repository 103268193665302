import {Map,List,fromJS} from 'immutable';
import {getAddressList, deleteAddress} from '@/api/service.js';

const defaultState = Map({
  page:Map({
    pageSize:10,
    offset:0
  }),
  addresses:List([]),
  hasMore:false,
  deleteError:Map({
    showError:false,
    errorMsg:'',
    showConfirm:false,
    addressId:0,
  }),
  pulled:false,
  showError:false,
  errorMsg:'',
  pageType:'',  // shipping_address; billing_address; '',
  callBack:'', // selected address callback
  scrollTop:0,
});
export default {
  namespace:'myAddresses',
  state:defaultState,
  reducers:{
    _initialState(state,{payload}){
      state = defaultState;
      state = state.set('pageType',payload.type);
      state = state.set('callBack',payload.callBack);
      return state;
    },
    _addAddresses(state,{payload}){
      let addresses = state.getIn(['addresses']);
      addresses = addresses.concat(payload.addresses);
      state = state.set('addresses',addresses);
      state = state.set('hasMore',payload.hasMore);
      return state;
    },
    _changeHasMore(state,{payload}){
      state = state.set('hasMore',payload.value);
      return state;
    },
    _changePageOffset(state,{payload}){
      state = state.setIn(['page','offset'],payload.value);
      return state;
    },
    _deleteAddress(state,{payload}){
      const addressId = payload.id;
      let addresses = state.get('addresses');
      let page = state.get('page');

      addresses = addresses.filter((item)=>{
        return item.get('id') != addressId;
      });
      const offset = page.get('offset') - 1 <= 0 ? 0 : page.get('offset') - 1;
      page = page.set('offset',offset);

      state = state.set('addresses',addresses);
      state = state.set('page',page);
      
      return state;
    },
    _showDeleteError(state,{payload}){
      state = state.setIn(['deleteError','showError'],payload.showError);
      state = state.setIn(['deleteError','errorMsg'],payload.errorMsg);
      return state;
    },
    _changePulled(state,{payload}){
      state = state.set('pulled',payload.value);
      return state;
    },
    _changeShowError(state,{payload}){
      state = state.set('showError',payload.showError);
      state = state.set('errorMsg',payload.errorMsg);
      return state;
    },
    _changeDeleteConfirm(state,{payload}){
      state = state.setIn(['deleteError','showConfirm'],payload.value);
      state = state.setIn(['deleteError','addressId'],payload.addressId)
      return state;
    },
    _setScrollTop(state,{payload}){
      state = state.set('scrollTop',payload.value);
      return state;
    }
  },
  effects:{
    *initialState({payload},{put,select}){
      // const addresses = yield select(({myAddresses})=>{
      //   return myAddresses.get('addresses');
      // });
      // console.log('-=-=-=-=-=-=-=-=-=-=');
      // console.log(addresses.toJS());
      yield put({type:'_initialState',payload});
    },
    *pullData({payload},{put,select,call}){
      const page = yield select(({myAddresses})=>{
        return myAddresses.get('page');
      });
      const result = yield call(getAddressList,{offset:page.get('offset'),pageSize:page.get('pageSize')});
      
      if(result.status == 'success'){
        const addresses = fromJS(result.data.locations);
        const hasMore = result.data.hasMore;
        yield put({type:'_addAddresses',payload:{addresses,hasMore}});
        yield put.resolve({type:'nextPage'});

      }else{
        yield put({type:'_changeShowError',payload:{showError:true,errorMsg:result.message}});
        payload && payload.reject && payload.reject();
      }
      yield put({type:'_changePulled',payload:{value:true}});
      payload && payload.resolve && payload.resolve();
    },
    *changeHasMore({payload},{put,select,call}){
      yield put({type:'_changeHasMore',payload});
    },
    *nextPage({payload},{put,select}){
      const page = yield select(({myAddresses})=>{
        return myAddresses.get('page');
      });

      yield put({type:'_changePageOffset',payload:{value:page.get('offset')+page.get('pageSize')}});
    },
    *deleteAddress({payload},{put,select,call}){
      const {addressId} = payload;
      const result = yield call(deleteAddress,addressId);
      console.log(result);
      if(result.status == 'success'){
        yield put({type:'_deleteAddress',payload:{id:addressId}});
        yield put({type:'changeDeleteConfirm',payload:{value:false,addressId:0}});
      }else{
        yield put({type:'showDeleteError',payload:{showError:true,errorMsg:result.message}});
      }
    },
    *showDeleteError({payload},{put,select,call}){
      yield put({type:'_showDeleteError',payload});
    },
    *changeDeleteConfirm({payload},{put}){
      yield put({type:'_changeDeleteConfirm',payload});
    },
    *setScrollTop({payload},{put}){
      yield put({type:'_setScrollTop',payload});
    }
  }
}