import { fetch } from 'dva';
import { Toast } from 'antd-mobile';
import { history } from 'umi';
import hash from 'hash.js';
import {NAEventType,sendRecord} from './aws_analytics';

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};
var num = 0;
const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const errortext = codeMessage[response.status] || response.statusText;
  Toast.show({content:`请求错误 ${response.status}: ${response.url},${errortext}`});
  const error = new Error(errortext);
  error.name = response.status;
  error.response = response;
  throw error;
};

export function GetCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length == 2) {
    return parts
      .pop()
      .split(";")
      .shift();
  }
  return null;
}

const cachedSave = (response, hashcode) => {
  /**
   * Clone a response data and store it in sessionStorage
   * Does not support data other than json, Cache only json
   */
  const contentType = response.headers.get('Content-Type');
  if (contentType && contentType.match(/application\/json/i)) {
    // All data is saved as text
    response
      .clone()
      .text()
      .then(content => {
        sessionStorage.setItem(hashcode, content);
        sessionStorage.setItem(`${hashcode}:timestamp`, Date.now());
      });
  }
  return response;
};

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [option] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, option) {
  const options = {
    responseType:'text',
    expirys: false,
    ...option,
  };
  // 所有请求需要携带 csfrToken
  const csrftoken = GetCookie('csrfToken');
  /**
   * Produce fingerprints based on url and parameters
   * Maybe url has the same parameters
   */
  const fingerprint = url + (options.body ? JSON.stringify(options.body) : '');
  const hashcode = hash
    .sha256()
    .update(fingerprint)
    .digest('hex');

  const defaultOptions = {
    credentials: 'include',
  };
  const newOptions = { ...defaultOptions, ...options };
  if (
    newOptions.method === 'POST' ||
    newOptions.method === 'PUT' ||
    newOptions.method === 'DELETE'
  ) {
    if (!(newOptions.body instanceof FormData)) {
      newOptions.headers = {
        'x-csrf-token':csrftoken,
        Accept: 'application/json',
        'Cache-Control':'no-cache',
        'X-DNS-Prefetch-Control': 'on',
        ...newOptions.headers,
      };
      // newOptions.body = JSON.stringify(newOptions.body);
    } else {
      console.log('newOptions.body is FormData')
      // newOptions.body is FormData
      newOptions.headers = {
        'x-csrf-token':csrftoken,
        'Content-Type': 'application/x-www-form-urlencoded',
        'Cache-Control':'no-cache',
        'X-DNS-Prefetch-Control': 'on',
        Accept: 'application/json',
        ...newOptions.headers,
      };
    }
  }

  const expirys = options.expirys && 60;
  // options.expirys !== false, return the cache,
  if (options.expirys !== false) {
    const cached = sessionStorage.getItem(hashcode);
    const whenCached = sessionStorage.getItem(`${hashcode}:timestamp`);
    if (cached !== null && whenCached !== null) {
      const age = (Date.now() - whenCached) / 1000;
      if (age < expirys) {
        const response = new Response(new Blob([cached]));
        return response.json();
      }
      sessionStorage.removeItem(hashcode);
      sessionStorage.removeItem(`${hashcode}:timestamp`);
    }
  }

  
  // let requestContent = NAEventType.request.content;
  // requestContent.category = 'request';
  // requestContent.link = url;
  // requestContent.data = newOptions;
  // sendRecord({type:NAEventType.request.type,
  //             content:requestContent});

  return fetch(url, newOptions)
    .then(checkStatus)
    .then(response => cachedSave(response, hashcode))
    .then(response => {

      // let responseContent = NAEventType.request.content;
      // responseContent.category = 'response';
      // responseContent.link = url;
      // responseContent.data = response;
      // sendRecord({type:NAEventType.request.type,
      //             content:responseContent});

      const clone = response.clone()
      const localPath = history.location.pathname
      response.json().then(e=>{
        console.log(history);
        
        if((e.code==='login' || e.code === 'freebieLogin') && localPath !== '/login'){
          let loginCallBackUrl = localPath;
          if(history.location.search){
            loginCallBackUrl += history.location.search;
          }
          
          history.push('/login?callback='+encodeURIComponent(loginCallBackUrl));
          return ;
        }
      })
      // DELETE and 204 do not return data by default
      // using .json will report an error.
      if (newOptions.method === 'DELETE' || response.status === 204) {
        return clone.text();
      }
     
      return clone.json();
    })
    .catch(e => {
      const status = e.name;
      if (status === 401) {
        // @HACK
        /* eslint-disable no-underscore-dangle */
        // window.g_app._store.dispatch({
        //   type: 'login/logout',
        // });
        return;
      }
      // environment should not be used
      if (status === 403) {
        num++;
        if (num > 2) {
          // history.push('/Exception/403');
          return e;
        };
        request(url, option)
        return e;
      }
      if (status <= 504 && status >= 500) {
        history.push('/page-404');
      }
      if (status >= 404 && status < 422) {
        history.push('/page-404');
      }

      sendRecord({type:NAEventType.exception.type,
                  content:e});

    });
}
