import { queryHomeData,queryShopCategory } from '../../../api/service';
import { Map,List } from 'immutable';
export default {
  //命名空间
  namespace: 'homeData',
  state: {
    //初始数据
    homePageData: {
      "activityInfo": {
        
       },
    },
    name: 'e',
    categoryAllList: List([]),
    searchFlag:false,
    
  },
  effects: {
    //管理异步操作
    *getHomePageEffects({ payload }, { call, put, select }) {
      //select用来获取 model 里的 state
      const { name } = yield select((state) => state.homeData);
      const params = { name, ...payload };
      //call 调用异步请求
      const data = yield call(queryHomeData,null);
      console.log('effects==', data);
      //put 发出一个 Action，调用reducers里的方法，类似于 dispatch
      yield put({
        // 调用当前 model 的 action 不需要添加 namespace
        type: 'changehomePageData',
        payload: data,
      });
    },
    *getCategoryEffects({ payload }, { call, put, select }) {
      console.log('getCategoryEffects==', payload);
      const data = yield call(queryShopCategory,{category_id:payload.category_id});
      console.log('effects==', data);
      yield put({
        type: 'changeShopCategory',
        payload: data
      })
    }
  },
  reducers: {
    //管理同步方法接收 action，同步更新 state
    changehomePageData(state, { payload }) {
      return {
        ...state,
        homePageData: payload,
      };
    },
    changeShopCategory(state, { payload }) {
      return {
        ...state,
        categoryAllList:payload
      }
    },
    changeSearchFlag(state,{payload})
    {
      return {
        ...state,
        searchFlag:payload
      }
    }
  },
};
