import { getCollectList,delCollect } from '../../../api/service';
import { Map } from 'immutable'
export default {
  namespace: 'myfavoriteData',
  state: {
    favoriteInfo:{},
  },
  reducers: {
    changeInfo(state, { payload }) {
      const twoC = state.favoriteInfo?.list;
      const newObj = { ...payload };
      if (state.favoriteInfo.page !== payload.page) {
        newObj.list = [
          ...(twoC || ''),
          ...newObj.list,
        ];
      } else {
        newObj.list = twoC;
      }
      return {
        ...state,
        favoriteInfo: newObj,
      };
    },
  },
  effects: {
      *getFavInfoEffect({ payload }, { call, put, select }) {
        console.log('payload=2323=',payload);
        const pageInfo = {
          page:payload.page ? payload.page : 1,
          pageSize:payload.pageSize ? payload.pageSize : 10,
        };
        try {
          const data = yield call(getCollectList,pageInfo);
          yield put({
              type:'changeInfo',
              payload:{...pageInfo,...data}
          })
          payload.resolve(data);
        } catch (error) {
          payload.reject('error');
        }
        
      },
      *delCollect({payload}, {call, put, select}) {
        const {resolve,reject} = payload
        try{
          const data = yield call(delCollect,{skuUuid:payload.skuUuid})
          resolve(data)
        }catch(error) {
          reject(error)
        }

      }
  }
};
