import {getloginInfo} from '@/api/service'
export default {
    namespace: 'headInfo',
    state: {
        loginInfo: {
            cartNum: 0,
            livingCategoryId:null
        },
        BouncedFlag:false,
        BouncedName:'',
        BouncedBtn:'',
        btn_src:'',
        animationEndState: {
            action:'',
            count:0
        },
        firstShow:true,
        inviteShow:false,
        warningPopup:false,
        otherWebInPopup:false,
        otherWebInPopupFirstShow:true,
        model:'living'
    },
    effects: {
        *getLoginInfoEffects({ payload }, { call, put, select }) {
            console.log(1111)
            const data = yield call(getloginInfo);
            yield put({
                type: 'changeLoginInfo',
                payload: data?.data,
            });
        },
    },
    reducers: {
        changeModel(state,{payload}){
            document.documentElement?.setAttribute('model',payload.model)
            return{
                ...state,
                model:payload.model
            }
        },
        changeLoginInfo(state, { payload }) {
            console.log('payload', payload);
            return {
                ...state,
                loginInfo: {...payload},
            };
        },
        changeBounced(state,{payload}){
            console.log(1111,payload)
            return{
                ...state,
                BouncedFlag:payload.BouncedFlag,
                BouncedName:payload.BouncedName,
                BouncedBtn:payload.BouncedBtn,
                btn_src:payload.btn_src
            }
        },
        changeAnimationEndState(state, { payload }) {
            const newObj = {
                ...state.animationEndState,
                ...payload
            }
            newObj.count += 1 
            return {
                ...state,
                animationEndState:payload
            }
        },
        changePcWarning(state, { payload }){
            return {
                ...state,
                warningPopup:payload
            }
        },
        changeinviteshow(state, { payload }){

            return {
                ...state,
                firstShow:false,
                inviteShow:payload
            }
        },
        changeOtherWebInPopup(state, { payload }){
            return {
                ...state,
                otherWebInPopupFirstShow:false,
                otherWebInPopup:payload
            }
        },
    }
}