import { cartDetail,delProduct,testClick } from '../../../api/service';
import { Map } from 'immutable'
export default {
  namespace: 'mycartData',
  state: {
    cartInfo: '',
  },
  reducers: {
    changeCartInfo(state, { payload }) {
      return {
        ...state,
        cartInfo: payload,
      };
    },
  },
  effects: {
      *getCartInfoEffect({ payload }, { call, put, select }) {
        try {
          
          const data = yield call(cartDetail,null)
          console.log('data==',data)
          yield put({
              type:'changeCartInfo',
              payload:data
          })
          if(data.changed == 1){
            payload.dispatch({
              type: 'headInfo/changeBounced',
              payload: {
                BouncedFlag: true,
                BouncedName: 'Your cart has changed, please double check before you check out!',
                BouncedBtn: '',
              },
            });
          }
        } catch (error) {
          console.log('error:',error)
        }
      },
      *deleteProduct({payload}, {call, put, select}) {
        const {resolve,reject} = payload
        try{
          const data = yield call(delProduct,{skuUuid:payload.skuUuid})
          resolve(data)
        }catch(error) {
          reject(error)
        }

      }
      
  }
};
