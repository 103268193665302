import { orderHistory } from '../../../api/service';
import { Map,List } from 'immutable'
export default {
  namespace: 'orderHistory',
  state: {
    orderList:'',
    pageNo:1,//当前页
    pageSize:10,//一页多少条
    total:0,//总条数
    pageNumber:0,//总页数
    status:'All Orders',
    searchContent:'',
    flag:true
  },
  effects: {
      *getorderHistoryEffect({ payload }, { call, put, select }) {
        const { status,searchContent,pageNo,pageSize } = yield select((state) => state.orderHistory);
        let itemSpan = ''
        if(status == 'All Orders'){
          itemSpan = 'all'
        }else if(status == 'On the Way'){
          itemSpan = 'placed'
        }else if(status == 'Canceled'){
          itemSpan = 'canceled'
        }else if(status == 'Delivered'){
          itemSpan = 'completed'
        }else{
          itemSpan = status
        }
        let params = {
              "pageNo":payload.pageNo,
              "pageSize":payload.pageSize,
              "status":itemSpan,
              "searchContent":searchContent
        }
        const data = yield call(orderHistory,params)
        console.log('data==',data)
        yield put({
            type:'changeOrderList',
            payload:data.data
        })
      },
      *changeOrderListEffect({ payload }, { call, put, select }) {
        const { status,pageNo,pageSize,searchContent } = yield select((state) => state.orderHistory);
        let itemSpan = ''
        if(status == 'All Orders'){
          itemSpan = 'all'
        }else if(status == 'On the Way'){
          itemSpan = 'placed'
        }else if(status == 'Canceled'){
          itemSpan = 'canceled'
        }else if(status == 'Delivered'){
          itemSpan = 'completed'
        }else{
          itemSpan = status
        }
        let params = {
              "pageNo":pageNo,
              "pageSize":pageSize,
              "status":itemSpan,
              "searchContent":searchContent
        }

        try {
          const data = yield call(orderHistory,params)
          yield put({
              type:'addOrderList',
              payload:data.data
          })
            payload.resolve(data);
          } catch (error) {
            payload.reject('error');
          }
      },
      *btnList({ payload }, { call, put, select }){
        const { status,searchContent } = yield select((state) => state.orderHistory);
        let itemSpan = ''
        if(status == 'All Orders'){
          itemSpan = 'all'
        }else if(status == 'On the Way'){
          itemSpan = 'placed'
        }else if(status == 'Canceled'){
          itemSpan = 'canceled'
        }else if(status == 'Delivered'){
          itemSpan = 'completed'
        }else{
          itemSpan = status
        }
        let params = {
              "pageNo":payload.pageNo,
              "pageSize":payload.pageSize,
              "status":itemSpan,
              "searchContent":payload.searchContent
        }
        const data = yield call(orderHistory,params)
        console.log('data==',data)
        yield put({
            type:'btnOrderList',
            payload:data.data
        })
      }
  },
  reducers: {
    changeOrderList(state, { payload }) {
      console.log(payload.orderList)
      return {
        ...state,
        orderList: payload.orderList,
        pageNo:payload.pageNo+1,
        pageSize:payload.pageSize,
        total:payload.total,
        pageNumber: Math.ceil(payload.total/payload.pageSize),
        flag:false
      };
    },
    btnOrderList(state,{payload}){
      return{
        ...state,
        orderList: payload.orderList,
      }
    },
    addOrderList(state, { payload }) {
      return {
        ...state,
        orderList: state.orderList.concat(payload.orderList),
        pageNo:payload.pageNo+1,
        pageSize:payload.pageSize,
        total:payload.total,
        flag:false
      };
    },
    changeStatus(state, { payload }){
      return {
        ...state,
        status:payload.status
      }
    },
    changeSearch(state,{payload}){
      return {
        ...state,
        searchContent:payload.searchContent
      }
    }
  }
};
